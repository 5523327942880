import React, { useState } from 'react';
import { Button } from '../../../aurora/components/Button/Button';
import { IconPlus } from '../../../aurora/icons';
import DataModal from '../../../components/DataModal';
import CompanyForm from './CompanyForm';
import CompaniesApi from '../../../api/companiesApi';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import { useDispatch } from 'react-redux';
import { addCompany } from '../../../state/companies';

const AddCompanyButton = () => {
  const [showModal, setShowModal] = useState(false);
  const axiosPrivate = useAxiosPrivate();
  const dispatch = useDispatch();

  const onClose = () => setShowModal(false);

  return (
    <div>
      <Button
        variant="primary"
        label="Add Company"
        iconLeft={IconPlus}
        onClick={() => setShowModal(true)}
      />
      <DataModal title={'Add company'} show={showModal} onClose={onClose}>
        <CompanyForm
          onSubmit={async (companyName, salesModel, useType) => {
            const response = await CompaniesApi.createCompany(
              axiosPrivate,
              companyName,
              salesModel,
              useType
            );
            dispatch(
              addCompany({ companyName, id: response._id, salesModel, useType })
            );
          }}
          successMessage="Successfully added company"
          errorMessage="Error adding company. Please try again or contact support"
          onClose={onClose}
        />
      </DataModal>
    </div>
  );
};

export default AddCompanyButton;

import React, { useEffect, useState } from 'react';
import {
  ColumnsConfig,
  EmptyConfig,
  SlideoutConfig,
  TopConfig,
} from '../../components/table/tableConfig';
import { format } from 'date-fns';
import {
  EventEntity,
  EventStatusesEnum,
  readableType,
} from '../../state/events/types';
import DataTableContainer from '../../components/table/DataTableContainer';
import {
  loadEvents,
  setCurrentPage,
  sortEvents,
  setCompanyIdsFilter,
  resetPage,
} from '../../state/events';
import { useSx } from 'dripsy';
import EventTableAction from './components/EventTableAction';
import EventsBulkAction from './components/EventsBulkAction';
import EventsStatusFilter from './components/EventsStatusFilter';
import EventsSearch from './components/EventsSearch';
import { IconNotification } from '../../aurora/icons';
import PageHeader from '../../components/layout/PageHeader';
import CompanyFilter from '../../components/filters/CompanyFilter/CompanyFilter';
import { isArtyc, selectAuth } from '../../state/auth';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../state/store';
import EventStatusLabel from './components/EventStatusLabel';
import EventShipmentSlideout from './components/EventShipmentSlideout';
import DataModal from '../../components/DataModal';
import { useSearchParams } from 'react-router-dom';
import EventsApi from '../../api/eventsApi';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import SnapshotsGraphContainer from '../shipments/components/graph/SnapshotsGraphContainer';
import PageDateFilter from '../../components/filters/PageDateFilter/PageDateFilter';
import styles from '../page.module.scss';
import { setDateFilter } from '../../state/events';
import MobileCompanyFilter from '../../components/filters/MobileChecklistFilter/MobileCompanyFilter';

const EventsPage = () => {
  const sx = useSx();
  const dispatch = useDispatch();
  const axios = useAxiosPrivate();
  const auth = useSelector(selectAuth);

  const { startDate, endDate } = useSelector(
    (state: RootState) => state.events.selectedFilters
  ) || { startDate: undefined, endDate: undefined };
  const parsedStartDate = startDate ? new Date(startDate) : undefined;
  const parsedEndDate = endDate ? new Date(endDate) : undefined;

  const { companyIds } = useSelector(
    (state: RootState) => state.events.selectedFilters || {}
  );
  const [searchParams] = useSearchParams();
  const [eventModal, setEventModal] = useState<EventEntity | null>(null);
  const initialOpenEventId = searchParams.get('event');
  const [initialOpenEvent, setInitialOpenEvent] = useState<
    EventEntity | undefined
  >(undefined);

  const loadEvent = async () => {
    if (initialOpenEventId !== null) {
      try {
        const event = await EventsApi.getEvent(axios, auth, initialOpenEventId);
        setInitialOpenEvent(event);
      } catch (e) {
        console.error(e);
      }
    }
  };

  useEffect(() => {
    loadEvent();
  }, [initialOpenEventId]);

  const topConfig: TopConfig = {
    text: 'Event Monitoring',
    action: <EventsStatusFilter />,
    additionalBars: [
      <>
        <EventsSearch />
        <div className={styles.filterGroup}>
          <CompanyFilter
            setCompanyIdsAction={(companyIds) =>
              dispatch(setCompanyIdsFilter(companyIds))
            }
            filteredCompanyIds={companyIds}
          />
          <MobileCompanyFilter
            setCompanyIdsAction={(companyIds) =>
              dispatch(setCompanyIdsFilter(companyIds))
            }
            filteredCompanyIds={companyIds}
          />
          <PageDateFilter
            setDateFilters={(startDate, endDate) =>
              dispatch(
                setDateFilter([
                  startDate?.toISOString(),
                  endDate?.toISOString(),
                ])
              )
            }
            clearDateFilters={() =>
              dispatch(setDateFilter([undefined, undefined]))
            }
            filters={[parsedStartDate, parsedEndDate]}
          />
        </div>
      </>,
    ],
  };
  const emptyConfig: EmptyConfig = {
    icon: IconNotification,
    title: 'No events found',
    body: "Once events are detected, they'll show up here",
  };
  const columnsConfig: ColumnsConfig<EventEntity> = {
    columns: [
      {
        title: 'Event Type',
        property: 'eventType',
        customData: (entity: EventEntity) => readableType[entity.eventType],
        customComponent: (data, _, onClick) => {
          return (
            <div
              style={sx({ fontWeight: '600', color: 'gray600' })}
              onClick={onClick}
            >
              {data}
            </div>
          );
        },
      },
      {
        title: 'Status',
        property: 'status',
        customComponent: (data, _, onClick) => (
          <EventStatusLabel
            status={data as EventStatusesEnum}
            onClick={onClick}
          />
        ),
      },
      {
        title: 'Company',
        property: 'companyName',
        showColumn: isArtyc(auth),
      },
      {
        title: 'Shipment ID',
        property: 'shipmentPid',
      },
      {
        title: 'Event ID',
        property: 'pid',
      },
      {
        title: 'Timestamp',
        property: 'eventStart',
        customData: (entity: EventEntity) =>
          format(new Date(entity.eventStart), 'M/d/yy, h:mm a'),
      },
      {
        title: 'Serial #',
        property: 'deviceSerialNumber',
      },
    ],
    actionColumn: (entity: EventEntity) => (
      <EventTableAction
        event={entity}
        showGraph={() => setEventModal(entity)}
      />
    ),
    bulkAction: (entities: EventEntity[]) => (
      <EventsBulkAction events={entities} />
    ),
  };

  const slideoutConfig: SlideoutConfig<EventEntity> = {
    slideout: (event, open, closeSlideout) => (
      <EventShipmentSlideout
        event={event}
        showGraph={() => setEventModal(event!)}
        open={event ? open : false}
        closeSlideout={() => {
          closeSlideout();
          setInitialOpenEvent(undefined);
        }}
      />
    ),
    initialOpenEntity: initialOpenEvent,
  };

  return (
    <>
      <PageHeader headingText="Events" />
      <DataTableContainer
        topConfig={topConfig}
        emptyConfig={emptyConfig}
        columnsConfig={columnsConfig}
        slideoutConfig={slideoutConfig}
        sortAction={sortEvents}
        loadDataAction={loadEvents}
        pageAction={setCurrentPage}
        stateName="events"
        resetAction={resetPage}
      />
      <DataModal
        title={`Device #${eventModal?.deviceSerialNumber}`}
        show={eventModal !== null}
        onClose={() => setEventModal(null)}
        width="1200px"
      >
        {eventModal && <SnapshotsGraphContainer event={eventModal} />}
      </DataModal>
    </>
  );
};

export default EventsPage;

import React, { useEffect, useState } from 'react';
import DataTableContainer from '../../components/table/DataTableContainer';
import {
  ColumnsConfig,
  SlideoutConfig,
  EmptyConfig,
  TopConfig,
} from '../../components/table/tableConfig';
import { ShipmentEntity } from '../../state/shipments/types';
import { IconCircleQuestion, IconShipment } from '../../aurora/icons';
import PageHeader from '../../components/layout/PageHeader';
import {
  loadShipments,
  setCurrentPage,
  sortShipments,
  setCompanyIdsFilter,
  resetPage,
  setModalShipments,
  setModalType,
  ShipmentModalTypeEnum,
  resetLoadedGraph,
} from '../../state/shipments';
import { useDispatch, useSelector } from 'react-redux';
import { setDateFilter, setDeviceTypesFilter } from '../../state/shipments';
import DeviceTypeFilter from '../../components/filters/DeviceTypeFilter/DeviceTypeFilter';
import CompanyFilter from '../../components/filters/CompanyFilter/CompanyFilter';
import { RootState } from '../../state/store';
import { isArtyc, selectAuth } from '../../state/auth';
import ShipmentSlideout from '../../components/shipmentSlideout/ShipmentSlideout';
import { formatDateTime, secondsToDuration } from '../../utils/dateUtil';
import { Tooltip } from '../../aurora/components/Tooltip/Tooltip';
import { useSx } from 'dripsy';
import ShipmentSearch from './components/ShipmentSearch';
import styles from '../page.module.scss';
import ShipmentsBulkAction from './components/ShipmentsBulkAction';
import PageDateFilter from '../../components/filters/PageDateFilter/PageDateFilter';
import MobileCompanyDeviceFilter from '../../components/filters/MobileChecklistFilter/MobileCompanyDeviceFilter';
import { usePDF } from 'react-to-pdf';
import { showToast } from '../../aurora/components/Toast/Toast';
import GraphsPdf from './components/graph/GraphsPdf';
import ShipmentPageModal from './components/ShipmentPageModal';
import { ViewAsCustomerToggle } from './components/ViewAsCustomerToggle';
import ShipmentTableAction from './ShipmentTableAction';

const ShipmentsPage = () => {
  const sx = useSx();
  const dispatch = useDispatch();
  const auth = useSelector(selectAuth);

  const { toPDF, targetRef } = usePDF();
  const [exportGraph, setExportGraph] = useState(false);

  const { startDate, endDate } = useSelector(
    (state: RootState) => state.shipments.selectedFilters
  ) || { startDate: undefined, endDate: undefined };
  const parsedStartDate = startDate ? new Date(startDate) : undefined;
  const parsedEndDate = endDate ? new Date(endDate) : undefined;

  const { deviceTypes, companyIds } = useSelector(
    (state: RootState) => state.shipments.selectedFilters || {}
  );

  const { modalShipments, modalType, loadedGraphs } = useSelector(
    (state: RootState) => state.shipments
  );

  useEffect(() => {
    if (
      exportGraph &&
      loadedGraphs > 0 &&
      loadedGraphs === modalShipments.length
    ) {
      // have to wait a sec after graphs load for animations to finish
      setTimeout(() => {
        toPDF({ filename: `graph.pdf` });
        dispatch(setModalShipments([]));
        dispatch(resetLoadedGraph());
        setExportGraph(false);
      }, 2000);
    }
  }, [loadedGraphs, exportGraph]);

  const openModal = (
    shipments: ShipmentEntity[],
    modalType: ShipmentModalTypeEnum
  ) => {
    dispatch(setModalShipments(shipments));
    dispatch(setModalType(modalType));
  };

  const closeModal = () => {
    dispatch(setModalShipments([]));
    dispatch(setModalType(null));
  };

  const exportGraphs = (shipments: ShipmentEntity[]) => {
    setExportGraph(true);
    dispatch(resetLoadedGraph());
    dispatch(setModalShipments(shipments));

    // loadedGraphs useEffect should handle the actual exporting once graphs load
    showToast({
      type: 'info',
      title: 'Exporting...',
      text: 'Please wait a moment for the export to finish',
    });
  };

  const topConfig: TopConfig = {
    text: 'Shipments',
    additionalBars: [
      <>
        <ShipmentSearch />
        <div className={styles.filterGroup}>
          <DeviceTypeFilter
            setDeviceTypesAction={setDeviceTypesFilter}
            filteredDeviceTypes={deviceTypes}
          />
          <CompanyFilter
            setCompanyIdsAction={(companyIds) =>
              dispatch(setCompanyIdsFilter(companyIds))
            }
            filteredCompanyIds={companyIds}
          />
          <MobileCompanyDeviceFilter
            setDeviceTypesAction={(deviceTypes) =>
              dispatch(setDeviceTypesFilter(deviceTypes))
            }
            filteredDeviceTypes={deviceTypes}
            setCompanyIdsAction={(companyIds) =>
              dispatch(setCompanyIdsFilter(companyIds))
            }
            filteredCompanyIds={companyIds}
          />
          <PageDateFilter
            setDateFilters={(startDate, endDate) =>
              dispatch(
                setDateFilter([
                  startDate?.toISOString(),
                  endDate?.toISOString(),
                ])
              )
            }
            clearDateFilters={() =>
              dispatch(setDateFilter([undefined, undefined]))
            }
            filters={[parsedStartDate, parsedEndDate]}
          />
        </div>
      </>,
    ],
  };
  const emptyConfig: EmptyConfig = {
    icon: IconShipment,
    title: 'No shipments found',
    body: "Once shipments are found, they'll show up here",
  };
  // TODO(test): test that company only shows for artyc admin, date is startTime + format
  const columnsConfig: ColumnsConfig<ShipmentEntity> = {
    columns: [
      {
        title: 'Device',
        property: 'deviceType',
      },
      {
        title: 'Serial #',
        property: 'serialNumber',
      },
      {
        title: 'Shipment ID',
        property: 'pid',
      },
      {
        title: 'Company',
        property: 'companyName',
        showColumn: isArtyc(auth),
      },
      {
        title: 'Start Date',
        property: 'startTime',
        customData: (shipment: ShipmentEntity) =>
          `${
            shipment.firstSync === null || shipment.firstSync === undefined
              ? 'Est. '
              : ''
          }${formatDateTime(shipment.startTime)}`,
        customComponent(data, _, onClick) {
          if ((data as string).startsWith('Est. ')) {
            return (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  gap: '8px',
                  alignItems: 'center',
                }}
                onClick={onClick}
              >
                <span style={sx({ color: 'neutral800' })}>{data}</span>
                <Tooltip label="We couldn't get a GPS lock to acquire an accurate time for this shipment. The start time is estimated from the upload time.">
                  <IconCircleQuestion
                    color="neutral800"
                    height={16}
                    width={16}
                  />
                </Tooltip>
              </div>
            );
          }
          return data;
        },
      },
      {
        title: 'Duration',
        property: 'lengthSec',
        customData: (shipment: ShipmentEntity) =>
          secondsToDuration(shipment.lengthSec),
      },
    ],
    actionColumn: (shipment: ShipmentEntity) => (
      <ShipmentTableAction shipment={shipment} exportGraphs={exportGraphs} />
    ),
    bulkAction: (shipments: ShipmentEntity[]) => (
      <ShipmentsBulkAction
        shipments={shipments}
        openModal={openModal}
        exportGraphs={exportGraphs}
      />
    ),
  };

  const slideoutConfig: SlideoutConfig<ShipmentEntity> = {
    slideout: (shipment, open, closeSlideout) => (
      <ShipmentSlideout
        shipment={shipment}
        showGraph={() => {
          openModal([shipment!], ShipmentModalTypeEnum.Graph);
        }}
        open={shipment ? open : false}
        closeSlideout={closeSlideout}
      />
    ),
  };

  return (
    <>
      <PageHeader headingText="Shipments" action={<ViewAsCustomerToggle />} />
      <DataTableContainer
        key="shipments"
        topConfig={topConfig}
        emptyConfig={emptyConfig}
        columnsConfig={columnsConfig}
        slideoutConfig={slideoutConfig}
        stateName={'shipments'}
        loadDataAction={loadShipments}
        sortAction={sortShipments}
        pageAction={setCurrentPage}
        resetAction={resetPage}
      />

      {modalType !== null && (
        <ShipmentPageModal
          modalType={modalType}
          modalShipments={modalShipments}
          onClose={closeModal}
        />
      )}

      {exportGraph && (
        <div
          style={{ position: 'absolute', top: '-9999px', left: '-9999px' }}
          ref={targetRef}
        >
          <GraphsPdf shipments={modalShipments} />
        </div>
      )}
    </>
  );
};

export default ShipmentsPage;

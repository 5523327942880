import React from 'react';
import { ShipmentEntity } from '../../state/shipments/types';
import { secondsToFullMinutes } from '../../utils/dateUtil';
import { ShippingProfileEntity } from '../../state/shippingProfile/types';
import Badge from '../../aurora/components/Badge/Badge';
import styles from './TempManagementSection.module.scss';

interface SectionProps {
  label: string;
  text: string;
}

const Section = ({ label, text }: SectionProps) => (
  <div>
    <div className={styles.label}>{label}</div>
    <div>{text}</div>
  </div>
);

const degC = (temp: number): string => `${temp}°C`;
const inMinutes = (seconds: number): string => `${seconds} minutes`;

interface Props {
  shipment: ShipmentEntity | undefined;
  shippingProfile?: ShippingProfileEntity;
}

const TempManagementSection = ({ shipment, shippingProfile }: Props) => {
  if (!shipment) {
    return null;
  }

  if (shippingProfile === undefined) {
    return (
      <div className={styles.content}>
        <div className={styles.contentRow}>
          <Section
            label={'Set Temperature'}
            text={degC(shipment.setTemperature)}
          />
          <Section
            label={'Sampling Rate'}
            text={inMinutes(shipment.logIncrement / 60 / 1000)}
          />
        </div>
        <div className={styles.contentRow}>
          <Section label={'Excursion Window'} text={inMinutes(30)} />
          <Section label={'Cooldown Timeout'} text={inMinutes(1.5 * 60)} />
        </div>
      </div>
    );
  }

  const {
    name: shippingProfileName,
    minSteadyStateTempC,
    maxSteadyStateTempC,
    samplingRateSec,
    excursionWindowSec,
    cooldownTimeoutSec,
    minCriticalTempC,
    maxCriticalTempC,
  } = shippingProfile;

  return (
    <div className={styles.content}>
      <div>
        <Badge
          content={`${shippingProfileName} (${minSteadyStateTempC}°C - ${maxSteadyStateTempC}°C)`}
        />
      </div>
      <div className={styles.contentRow}>
        <Section label={'Min. Temp'} text={degC(minSteadyStateTempC)} />
        <Section label={'Max. Temp'} text={degC(maxSteadyStateTempC)} />
      </div>
      <div className={styles.contentRow}>
        <Section
          label={'Sampling Rate'}
          text={inMinutes(secondsToFullMinutes(samplingRateSec))}
        />
        <Section
          label={'Excursion Window'}
          text={inMinutes(secondsToFullMinutes(excursionWindowSec))}
        />
      </div>
      {(minCriticalTempC !== undefined || maxCriticalTempC !== undefined) && (
        <div className={styles.contentRow}>
          {minCriticalTempC !== undefined && (
            <Section
              label={'Critical Temp. Low'}
              text={degC(minCriticalTempC)}
            />
          )}
          {maxCriticalTempC !== undefined && (
            <Section
              label={'Critical Temp. High'}
              text={degC(maxCriticalTempC)}
            />
          )}
        </div>
      )}
      <div className={styles.contentRow}>
        <Section
          label={'Cooldown Timeout'}
          text={inMinutes(secondsToFullMinutes(cooldownTimeoutSec))}
        />
      </div>
    </div>
  );
};

export default TempManagementSection;

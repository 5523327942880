import React from 'react';
import Badge from '../../../aurora/components/Badge/Badge';
import { PcbTestStatus } from '../../../state/pcbTests/types';

interface Props {
  status: PcbTestStatus;
}
const PcbTestStatusLabel = ({ status }: Props) => {
  const label = status.charAt(0).toUpperCase() + status.slice(1).toLowerCase();

  return <Badge content={label} variant={status} />;
};

export default PcbTestStatusLabel;

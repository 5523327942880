import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IconArrowRight, IconDownload } from '../../aurora/icons';
import ActionButton from '../../components/table/ActionButton';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import { isArtyc, selectAuth } from '../../state/auth';
import {
  downloadCsvAction,
  setModalShipments,
  setModalType,
  ShipmentModalTypeEnum,
} from '../../state/shipments';
import { ShipmentEntity } from '../../state/shipments/types';
import { AppDispatch } from '../../state/store';
import ShipmentGraphButton from './components/ShipmentGraphButton';
import ShipmentMapButton from './components/ShipmentMapButton';

interface Props {
  shipment: ShipmentEntity;
  exportGraphs: (shipments: ShipmentEntity[]) => void;
}
const ShipmentTableAction = ({ shipment, exportGraphs }: Props) => {
  const dispatch = useDispatch<AppDispatch>();
  const axiosPrivate = useAxiosPrivate();
  const auth = useSelector(selectAuth);

  const allowMapButton = shipment.hasGeoData;
  return (
    <div style={{ display: 'flex' }}>
      <ShipmentGraphButton
        onClick={() => {
          dispatch(setModalType(ShipmentModalTypeEnum.Graph));
          dispatch(setModalShipments([shipment]));
        }}
      />
      {isArtyc(auth) && allowMapButton ? (
        <ShipmentMapButton
          onClick={() => {
            dispatch(setModalType(ShipmentModalTypeEnum.Map));
            dispatch(setModalShipments([shipment]));
          }}
        />
      ) : null}
      <ActionButton
        icon={IconArrowRight}
        onClick={() => exportGraphs([shipment])}
        label="Export graph"
      />
      <ActionButton
        label="Download CSV"
        icon={IconDownload}
        onClick={() =>
          dispatch(
            downloadCsvAction({
              axios: axiosPrivate,
              auth,
              shipments: [shipment],
              fullLog: false,
            })
          )
        }
      />
    </div>
  );
};

export default ShipmentTableAction;

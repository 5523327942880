import React, { ComponentType, useEffect, useState } from 'react';
import {
  ShipmentEntity,
  ShipmentSnapshotEntity,
} from '../../state/shipments/types';
import ShipmentInfoCard from './ShipmentInfoCard';
import {
  Accordion,
  AccordionSection,
} from '../../aurora/components/Accordion/Accordion';
import { IconChartArrow, IconSnow, IconWarning } from '../../aurora/icons';
import TempManagementSection from './TempManagementSection';
import DeviceDataSection from './DeviceDataSection';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import { selectAuth } from '../../state/auth';
import { useSelector } from 'react-redux';
import ShipmentsApi from '../../api/shipmentsApi';
import { Text } from '../../aurora/typography/Text/Text';
import { EventEntity } from '../../state/events/types';
import EventsApi from '../../api/eventsApi';
import { IconProps } from '../../aurora/icons/IconBase';
import EventsSection from './EventsSection';
import Slideout from '../../aurora/components/Slideout/Slideout';
import SlideoutFooter from './SlideoutFooter';
import ShippingProfilesApi from '../../api/shippingProfilesApi';
import { ShippingProfileEntity } from '../../state/shippingProfile/types';

const mkHeader = (title: string, Icon: ComponentType<IconProps>) => (
  <div style={{ display: 'flex', gap: '8px' }}>
    <Icon height={20} width={20} />
    {title}
  </div>
);

interface Props {
  shipment: ShipmentEntity | undefined;
  showGraph: () => void;
  selectedEventId?: string;
  open: boolean;
  closeSlideout: () => void;
}
const ShipmentSlideout = ({
  shipment,
  showGraph,
  selectedEventId,
  open,
  closeSlideout,
}: Props) => {
  const axiosPrivate = useAxiosPrivate();
  const auth = useSelector(selectAuth);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [snapshots, setSnapshots] = useState<ShipmentSnapshotEntity[]>([]);
  const [events, setEvents] = useState<EventEntity[]>([]);
  const [shippingProfile, setShippingProfile] = useState<
    ShippingProfileEntity | undefined
  >(undefined);

  const loadData = async () => {
    if (shipment !== undefined) {
      try {
        setLoading(true);
        setError(false);

        const shippingProfileResp =
          shipment.shippingProfile !== undefined
            ? await ShippingProfilesApi.getShippingProfile(
                axiosPrivate,
                auth,
                shipment.shippingProfile
              )
            : undefined;
        setShippingProfile(shippingProfileResp);

        const snapshotResp = await ShipmentsApi.getShipmentSnapshots(
          axiosPrivate,
          auth,
          shipment._id
        );
        setSnapshots(snapshotResp);

        const eventResp = await EventsApi.getEventsForShipment(
          axiosPrivate,
          auth,
          shipment._id
        );
        setEvents(eventResp);
      } catch (e) {
        setError(true);
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    loadData();
  }, [shipment]);

  const sections: AccordionSection[] = [
    {
      header: mkHeader('Temperature Management', IconSnow),
      body: (
        <TempManagementSection
          shipment={shipment}
          shippingProfile={shippingProfile}
        />
      ),
    },
    {
      header: mkHeader('Device Data', IconChartArrow),
      body: <DeviceDataSection snapshots={snapshots} showGraph={showGraph} />,
    },
  ];

  let accordionOpenIdx: number | undefined = undefined;
  if (events.length > 0) {
    if (
      selectedEventId !== undefined &&
      events.find((event) => event._id === selectedEventId) !== undefined
    ) {
      accordionOpenIdx = sections.length;
    }

    sections.push({
      header: mkHeader('Events', IconWarning),
      body: <EventsSection events={events} />,
    });
  }

  return (
    <Slideout
      open={open}
      onClose={closeSlideout}
      title="Shipment Information"
      footer={
        <SlideoutFooter shipment={shipment} closeSlideout={closeSlideout} />
      }
    >
      <div
        style={{
          display: 'flex',
          gap: '24px',
          flexDirection: 'column',
        }}
      >
        <ShipmentInfoCard shipment={shipment} snapshots={snapshots} />
        {loading ? (
          <Text size="m">Loading...</Text>
        ) : error ? (
          <Text size="m">
            An error occurred loading part of this page, please close and open
            again
          </Text>
        ) : (
          <Accordion sections={sections} initialOpenIdx={accordionOpenIdx} />
        )}
      </div>
    </Slideout>
  );
};

export default ShipmentSlideout;

export enum SalesModelEnum {
  CaaS = 'Cooling as a Service',
  Lease = 'Lease',
}

export enum UseTypeEnum {
  IntraCampus = 'Intra-Campus',
  Slope = 'Slope',
}

export interface CompanyEntity {
  _id: string;
  companyName: string;
  salesModel: SalesModelEnum;
  useType: UseTypeEnum | null;
}

export interface CompanyFilters {
  search?: string;
}

import React from 'react';
import {
  ColumnsConfig,
  EmptyConfig,
  TopConfig,
} from '../../components/table/tableConfig';
import DataTableContainer from '../../components/table/DataTableContainer';
import PageHeader from '../../components/layout/PageHeader';
import { isArtyc, selectAuth } from '../../state/auth';
import { CompanyEntity } from '../../state/companies/types';
import { useSelector } from 'react-redux';
import { IconCompany } from '../../aurora/icons';
import {
  loadCompanies,
  resetPage,
  setCurrentPage,
  sortCompanies,
} from '../../state/companies';
import AddCompanyButton from './components/AddCompanyButton';
import CompanyEditButton from './components/CompanyEditButton';
import CompanySearch from './components/CompanySearch';

const CompaniesPage = () => {
  const auth = useSelector(selectAuth);

  const topConfig: TopConfig = {
    text: 'Companies',
    additionalBars: [<CompanySearch key="search" />],
  };
  const emptyConfig: EmptyConfig = {
    icon: IconCompany,
    title: 'No companies found',
  };
  const columnsConfig: ColumnsConfig<CompanyEntity> = {
    columns: [
      {
        title: 'Name',
        property: 'companyName',
      },
      {
        title: 'Sales Model',
        property: 'salesModel',
      },
      {
        title: 'Use Type',
        property: 'useType',
      },
    ],
    actionColumn: (entity: CompanyEntity) => (
      <CompanyEditButton company={entity} />
    ),
  };

  if (!isArtyc(auth)) {
    return null;
  }

  return (
    <>
      <PageHeader headingText="Companies" action={<AddCompanyButton />} />
      <DataTableContainer
        topConfig={topConfig}
        emptyConfig={emptyConfig}
        columnsConfig={columnsConfig}
        sortAction={sortCompanies}
        loadDataAction={loadCompanies}
        pageAction={setCurrentPage}
        stateName="companies"
        resetAction={resetPage}
      />
    </>
  );
};

export default CompaniesPage;

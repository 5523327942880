import React from 'react';
import styles from './style.module.scss';

interface Props {
  content: string;
  variant?: 'primary' | 'success' | 'failure' | 'neutral';
}

const Badge = ({ content, variant = 'primary' }: Props) => {
  const variantClassName =
    variant === 'primary'
      ? styles.primary
      : variant === 'success'
      ? styles.success
      : variant === 'failure'
      ? styles.failure
      : styles.neutral;

  return <div className={`${variantClassName} ${styles.badge}`}>{content}</div>;
};

export default Badge;
